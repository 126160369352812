import React from "react";

import { usePlanStore } from "../../../../store/PlanStore";

import PageInfo from "../PageInfo";
import WaterFilterValues from "./WaterFilterValues";
import HistoryLine from "./history/HistoryLine";

import { getWaterFilterPropertiesURL } from "../../../../utils/path.utils";

import { ReactComponent as ArrowRightSVG } from "../../../../assets/arrow-right.svg";
import { ReactComponent as PencilSVG } from "../../../../assets/pencil.svg";
import Redirect from "../../../../components/redirect/Redirect";
import RedirectLink from "../../../../components/redirect/RedirectLink";

function WaterFilterInfo() {
  const [showMore, changeShowMore] = React.useState(false);
  const { waterFilter } = usePlanStore();

  if (waterFilter == null) return null;

  if (waterFilter.is_fill_properties)
    return (
      <Redirect
        to={getWaterFilterPropertiesURL(waterFilter.id)}
        replace={true}
      />
    );

  return (
    <PageInfo>
      <div
        className={["sensor-info", showMore ? "more" : undefined]
          .filter((e) => e)
          .join(" ")}
      >
        <WaterFilterValues waterFilter={waterFilter} />

        <HistoryLine waterFilter={waterFilter} active={showMore} />

        <RedirectLink
          href={getWaterFilterPropertiesURL(waterFilter.id)}
          className="edit-button"
        >
          <PencilSVG />
        </RedirectLink>

        <div
          className="show-more-wrap"
          onClick={() => changeShowMore(!showMore)}
        >
          <button type="button" className="show-more">
            <ArrowRightSVG
              style={{
                transform: showMore ? "rotate(-90deg)" : "rotate(90deg)",
              }}
            />
          </button>
        </div>
      </div>
    </PageInfo>
  );
}

export default WaterFilterInfo;
