import { difference } from "lodash";
import React from "react";
import useFetch from "../hooks/useFetch";
import calcApi from "../service/calcApi";

import sensorFactory from "./factory/sensorFactory";
import PlanInterface from "./interface/PlanInterface";
import SensorDataInterface from "./interface/SensorDataInterface";
import SensorInterface from "./interface/SensorInterface";
import SensorPropertiesInterface from "./interface/SensorPropertiesInterface";
import { HydrawiseSettingsInterface } from "./interface/HydrawiseSettingsInterface";
import WaterFilterPropertiesInterface from "./interface/WaterFilterPropertiesInterface";
import WaterFilterInterface from "./interface/WaterFilterInterface";
import waterFilterFactory from "./factory/waterFilterFactory";
import WaterFilterDataInterface from "./interface/WaterFilterDataInterface";
import FertilizerPropertiesInterface from "./interface/FertilizerPropertiesInterface";
import FertilizerInterface from "./interface/FertilizerInterface";
import fertilizerFactory from "./factory/fertilizerFactory";
import FertilizerDataInterface from "./interface/FertilizerDataInterface";

interface PlanContextInterface {
  planId: string | undefined;
  data: PlanInterface | undefined;
  sensorList: SensorInterface[] | undefined;
  waterFilter: WaterFilterInterface | undefined;
  hydrawiseSettings: HydrawiseSettingsInterface | undefined;
  sensorSerialNumbers: string[];
  waterFilterSerialNumber: string | undefined;
  soilNotificationEnabled: boolean | undefined;
  changeSensorData: Function;
  changeWaterFilterData: Function;
  changeSoilNotificationEnabledValue: Function;
  changeSensorProperties: Function;
  changeWaterFilterProperties: Function;
  changeData: Function;
  changeHydrawiseSettings: (value: HydrawiseSettingsInterface) => void;
  fertilizer: FertilizerInterface | undefined;
  fertilizerSerialNumber: string | undefined;
  changeFertilizerData: Function;
  changeFertilizerProperties: Function;
}

const PlanContext = React.createContext<PlanContextInterface>({
  planId: undefined,
  data: undefined,
  sensorList: undefined,
  waterFilter: undefined,
  fertilizer: undefined,
  hydrawiseSettings: undefined,
  sensorSerialNumbers: [],
  waterFilterSerialNumber: undefined,
  fertilizerSerialNumber: undefined,
  soilNotificationEnabled: undefined,
  changeSensorData() {},
  changeSensorProperties() {},
  changeWaterFilterData() {},
  changeWaterFilterProperties() {},
  changeSoilNotificationEnabledValue() {},
  changeData() {},
  changeHydrawiseSettings(value) {},
  changeFertilizerData() {},
  changeFertilizerProperties() {},
});

function useSensorSerialNumbers(
  _sensorProperties: SensorPropertiesInterface[] | undefined
) {
  const [sensorSerialNumbers, changeSensorSerialNumbers] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    const new_sn =
      _sensorProperties?.reduce((acc, s) => {
        if (
          s.serial_number != null &&
          s.serial_number !== "" &&
          acc.indexOf(s.serial_number) < 0
        ) {
          acc.push(s.serial_number);
        }
        return acc;
      }, [] as string[]) ?? [];

    changeSensorSerialNumbers((old) => {
      if (
        difference(old, new_sn).length > 0 ||
        difference(new_sn, old).length > 0
      ) {
        return new_sn;
      }

      return old;
    });
  }, [_sensorProperties]);

  return sensorSerialNumbers;
}

function usePlanStore() {
  return React.useContext(PlanContext);
}

function PlanProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const planId = React.useMemo(
    () =>
      new window.URLSearchParams(window.location.search.slice(1)).get(
        "planId"
      ) ?? undefined,
    []
  );

  const recalculateCaches = React.useMemo(
    () =>
      planId != null
        ? async () => {
            await calcApi.recalculateCachesByPlan(planId);
          }
        : undefined,
    [planId]
  );

  useFetch(recalculateCaches);

  const [data, changeData] = React.useState<PlanInterface | undefined>();

  const [hydrawiseSettings, changeHydrawiseSettings] = React.useState<
    HydrawiseSettingsInterface | undefined
  >();

  const [soilNotificationEnabled, changeSoilNotificationEnabledValue] =
    React.useState<boolean | undefined>();

  const [_sensorProperties, changeSensorProperties] = React.useState<
    SensorPropertiesInterface[] | undefined
  >(undefined);

  const [_sensorData, changeSensorData] = React.useState<
    SensorDataInterface[] | undefined
  >(undefined);

  const sensorList = React.useMemo<SensorInterface[] | undefined>(() => {
    if (
      data?.elements == null ||
      data.elements?.length === 0 ||
      _sensorProperties == null
    )
      return undefined;

    return data.elements
      .filter((e: any) => e.type === "sensor")
      .map((s: any) => {
        const properties = _sensorProperties?.find((d) => d.id === s.id);

        const data = properties
          ? _sensorData?.find(
              (d: SensorDataInterface) =>
                properties.serial_number?.toUpperCase() ===
                d.serial_number?.toUpperCase()
            )
          : undefined;

        return sensorFactory({
          id: s.id,
          x: s.x,
          y: s.y,
          prefix: s.name,
          ...(properties ?? {}),
          ...(data ?? {}),
        });
      });
  }, [data?.elements, _sensorData, _sensorProperties]);

  const sensorSerialNumbers = useSensorSerialNumbers(_sensorProperties);

  const [_waterFilterProperties, changeWaterFilterProperties] = React.useState<
    WaterFilterPropertiesInterface[] | undefined
  >();

  const [_waterFilterData, changeWaterFilterData] = React.useState<
    WaterFilterDataInterface[] | undefined
  >(undefined);

  const waterFilter = React.useMemo<WaterFilterInterface | undefined>(() => {
    if (
      data?.elements == null ||
      data.elements?.length === 0 ||
      _waterFilterProperties == null
    )
      return undefined;

    const waterFilter = data?.elements.find(
      (e) =>
        e.type === "system-element" &&
        (e.systemType === "water-filter" || e.systemType === "combi-box")
    );
    if (waterFilter == null) {
      return undefined;
    }

    const properties = _waterFilterProperties.find(
      (d) => d.id === waterFilter.id
    );

    const mergedProperties = properties
      ? _waterFilterData?.find(
          (d: WaterFilterDataInterface) =>
            properties.serial_number?.toUpperCase() ===
            d.serial_number?.toUpperCase()
        )
      : undefined;

    return waterFilterFactory({
      id: waterFilter.id,
      x: waterFilter.x,
      y: waterFilter.y,
      prefix: waterFilter.name,
      ...(properties ?? {}),
      ...(mergedProperties ?? {}),
    });
  }, [data?.elements, _waterFilterData, _waterFilterProperties]);

  const waterFilterSerialNumber = React.useMemo(() => {
    const serialNumber = waterFilter?.serial_number;
    return serialNumber !== "" ? serialNumber : undefined;
  }, [waterFilter]);

  //fertilizer
  const [_fertilizerProperties, changeFertilizerProperties] = React.useState<
    FertilizerPropertiesInterface[] | undefined
  >();

  const [_fertilizerData, changeFertilizerData] = React.useState<
    FertilizerDataInterface[] | undefined
  >(undefined);

  const fertilizer = React.useMemo<FertilizerInterface | undefined>(() => {
    if (
      data?.elements == null ||
      data.elements?.length === 0 ||
      _fertilizerProperties == null
    )
      return undefined;

    const fertilizerElement = data?.elements.find(
      (e) => e.type === "system-element" && e.systemType === "fertilizer"
    );
    if (fertilizerElement == null) return;

    const properties = _fertilizerProperties.find(
      (d) => d.id === fertilizerElement.id
    );

    const mergedProperties = properties
      ? _fertilizerData?.find(
          (r: FertilizerDataInterface) =>
            properties.serial_number?.toUpperCase() ===
            r.serial_number?.toUpperCase()
        )
      : undefined;

    return fertilizerFactory({
      id: fertilizerElement.id,
      x: fertilizerElement.x,
      y: fertilizerElement.y,
      prefix: fertilizerElement.name,
      ...(properties ?? {}),
      ...(mergedProperties ?? {}),
    });
  }, [data?.elements, _fertilizerData, _fertilizerProperties]);

  const fertilizerSerialNumber = React.useMemo(() => {
    const serialNumber = fertilizer?.serial_number;
    return serialNumber !== "" ? serialNumber : undefined;
  }, [fertilizer]);
  return (
    <PlanContext.Provider
      value={{
        planId,
        data,
        hydrawiseSettings,
        sensorList,
        sensorSerialNumbers,
        waterFilter,
        waterFilterSerialNumber,
        fertilizer,
        fertilizerSerialNumber,
        soilNotificationEnabled,
        changeSensorData,
        changeWaterFilterData,
        changeSoilNotificationEnabledValue,
        changeSensorProperties,
        changeWaterFilterProperties,
        changeData,
        changeHydrawiseSettings,
        changeFertilizerData,
        changeFertilizerProperties,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}

export type { PlanContextInterface };

export { usePlanStore, PlanProvider };
