import React from "react";
import useFetch from "../../../../hooks/useFetch";
import calcApi from "../../../../service/calcApi";
import { usePlanStore } from "../../../../store/PlanStore";
import { getOffsetsAndSizeByElements } from "@dvsproj/ipat-core/planUtils";

const PADDING = 200;

function PlanImage() {
  const plan = usePlanStore();

  const [image, changeImage] = React.useState<string | undefined>();

  const extrimePoints = React.useMemo(() => {
    if (plan?.data?.elements == null) return undefined;
    let elems = [...plan.data.elements];
    if (plan?.data?.background?.src != null) {
      elems.push({ type: "background", ...plan.data.background });
    }
    return getOffsetsAndSizeByElements(elems, PADDING, 0, true);
  }, [plan?.data]);

  const fetchPlanBg = React.useMemo(
    () =>
      plan?.data != null
        ? async () => {
            const blob = await calcApi.generateImage(
              plan.planId,
              plan.data,
              {
                background: 0,
                overlay: 0,
                area: 0,
                sprinkler: 0,
              },
              {
                areaWatermark: true,
                sprinklerElements: false,
                sprinklersIrrigation: true,
              },
              {
                uncutBackground: true,
                padding: PADDING,
              }
            );
            changeImage(blob);
          }
        : undefined,
    [plan?.planId, plan?.data]
  );

  useFetch(fetchPlanBg);

  if (extrimePoints == null || image == null) return null;

  return (
    <g
      transform={`translate(
      ${extrimePoints.offsetX ?? 0}, ${extrimePoints.offsetY ?? 0})`}
    >
      <image
        href={image}
        width={extrimePoints.width}
        height={extrimePoints.height}
        x={0}
        y={0}
      />
    </g>
  );
}

export default PlanImage;
