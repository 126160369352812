import React, { CSSProperties } from "react";
import { useIntl } from "react-intl";

import { usePlanStore } from "../../../../store/PlanStore";
import PropertiesWrapper from "./PropertiesWrapper";

import TextInput from "../../../../components/inputs/TextInput";

import PropertiesItemWithControl from "./PropertiesItemWithControl";
import PropertiesItemWithInput from "./PropertiesItemWithInput";

import { ReactComponent as CombiBoxPropertiesSVG } from "../../../../assets/combi-box-properties.svg";
import { ReactComponent as WaterFilterPropertiesSVG } from "../../../../assets/water-filter-properties.svg";
import { ReactComponent as WaterFilterInletSVG } from "../../../../assets/water-filter-inlet.svg";
import { ReactComponent as WaterFilterOutletSVG } from "../../../../assets/water-filter-outlet.svg";

import useRedirect from "../../../../hooks/useRedirect";
import { getWaterFilterInfoURL } from "../../../../utils/path.utils";
import WaterFilterPropertiesInterface from "../../../../store/interface/WaterFilterPropertiesInterface";
import calcApi from "../../../../service/calcApi";
import PropertiesWithCheckbox from "./PropertiesWithCheckbox";
import { debounce } from "lodash";

function WaterFilterPropertiesElement({
  save,
  close,
  defaultName,
  defaultSerialNumber,
  defaultPressureDropLimit = 0.2,
  defaultInletPressureLimit,
  defaultPressureDropNotification,
  defaultInletPressureNotification,
  type,
}: Readonly<{
  save: Function;
  close: Function;
  defaultName?: string;
  defaultSerialNumber?: string;
  defaultPressureDropLimit?: number;
  defaultInletPressureLimit?: number;
  defaultPressureDropNotification?: boolean;
  defaultInletPressureNotification?: boolean;
  type: "combi-box" | "water-filter" | undefined;
}>) {
  const { formatMessage } = useIntl();

  const [name, changeName] = React.useState(defaultName ?? "");

  const [serial_number, changeSerialNumber] = React.useState(
    defaultSerialNumber ?? ""
  );

  const [pressure_drop_limit, changePressureDropLimit] = React.useState(
    defaultPressureDropLimit
  );
  const [inlet_pressure_limit, changeInletPressureLimit] = React.useState(
    defaultInletPressureLimit
  );
  const [pressure_drop_notification, changePressureDropNotification] =
    React.useState(defaultPressureDropNotification);
  const [inlet_pressure_notification, changeInletPressureNotification] =
    React.useState(defaultInletPressureNotification);

  const initialRef = React.useRef(false);

  // save after update
  React.useEffect(() => {
    if (initialRef.current) {
      save({
        name,
        serial_number,
        pressure_drop_limit,
        inlet_pressure_limit,
        pressure_drop_notification,
        inlet_pressure_notification,
      });
    }
    initialRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    serial_number,
    pressure_drop_limit,
    inlet_pressure_limit,
    pressure_drop_notification,
    inlet_pressure_notification,
  ]);

  return (
    <PropertiesWrapper
      elementTitle={formatMessage({ id: "waterfilter.properties.sub_title" })}
      ElementIcon={
        type === "combi-box" ? CombiBoxPropertiesSVG : WaterFilterPropertiesSVG
      }
      title={formatMessage({ id: "waterfilter.properties.title" })}
      submit={() => {
        save({
          name,
          serial_number,
          pressure_drop_limit,
          inlet_pressure_limit,
          pressure_drop_notification,
          inlet_pressure_notification,
        });
        close();
      }}
    >
      <PropertiesItemWithInput
        className="properties-item"
        title={formatMessage({ id: "waterfilter.properties.name" })}
      >
        <TextInput value={name} onChange={changeName} />
      </PropertiesItemWithInput>

      <PropertiesItemWithInput
        className="properties-item"
        title={formatMessage({ id: "waterfilter.properties.serial_number" })}
      >
        <TextInput
          value={serial_number}
          className="input-uppercase"
          onChange={(v: string) => changeSerialNumber(v.toUpperCase())}
        />
      </PropertiesItemWithInput>

      <div className="properties-separator" />

      <PropertiesItemWithControl
        key={["pressure_drop_limit", pressure_drop_limit]
          .filter((e) => e)
          .join("-")}
        className="properties-item"
        Icon={WaterFilterInletSVG}
        title={formatMessage({
          id: "waterfilter.properties.pressure_drop_limit",
        })}
        value={pressure_drop_limit}
        valueDelta={0.1}
        max={10}
        valueUnit="Bar"
        onChange={changePressureDropLimit}
        style={
          {
            "--color": "#ff7600",
          } as CSSProperties
        }
      />

      <PropertiesWithCheckbox
        title={formatMessage({
          id: "waterfilter.properties.pressure_drop_notification",
        })}
        className="properties-item"
        value={pressure_drop_notification}
        onChange={changePressureDropNotification}
      />

      <div className="properties-separator" />

      <PropertiesItemWithControl
        key={["inlet_pressure_limit", inlet_pressure_limit]
          .filter((e) => e)
          .join("-")}
        className="properties-item"
        Icon={WaterFilterOutletSVG}
        title={formatMessage({
          id: "waterfilter.properties.inlet_pressure_limit",
        })}
        value={inlet_pressure_limit}
        valueDelta={0.1}
        max={10}
        valueUnit="Bar"
        onChange={changeInletPressureLimit}
        style={
          {
            "--color": "#ff7600",
          } as CSSProperties
        }
      />

      <PropertiesWithCheckbox
        title={formatMessage({
          id: "waterfilter.properties.inlet_pressure_notification",
        })}
        className="properties-item"
        value={inlet_pressure_notification}
        onChange={changeInletPressureNotification}
      />

      <div className="properties-separator" />
    </PropertiesWrapper>
  );
}

function WaterFilterProperties() {
  const { planId, data, waterFilter, changeWaterFilterProperties } =
    usePlanStore();
  const redirect = useRedirect();

  const waterFilterElement = data?.elements.find(
    (e) =>
      e.type === "system-element" &&
      ["water-filter", "combi-box"].includes(e.systemType)
  );

  const saveWaterFilter = React.useCallback(
    async (id: string, properties: WaterFilterPropertiesInterface) => {
      if (planId == null || data == null) {
        throw new Error(`plan data is null`);
      }

      if (waterFilterElement == null) throw new Error(`Sensor ${id} not found`);

      const sensorData = {
        ...properties,
        id: waterFilterElement.id,
      } as WaterFilterPropertiesInterface;

      changeWaterFilterProperties((list) => [
        ...(list?.filter((s) => s.id !== id) ?? []),
        sensorData,
      ]);

      const updatedWaterFilter = await calcApi.saveWaterFilter(
        waterFilterElement.id,
        planId,
        sensorData
      );

      if (updatedWaterFilter != null) {
        changeWaterFilterProperties((list) => [
          ...(list?.filter((s) => s.id !== id) ?? []),
          updatedWaterFilter,
        ]);
      }
    },
    [planId, data, waterFilterElement, changeWaterFilterProperties]
  );

  const save = React.useMemo(
    () =>
      debounce((data) => {
        if (waterFilter == null) return;
        saveWaterFilter(waterFilter.id, data);
      }, 300),
    [saveWaterFilter, waterFilter]
  );

  const elementType =
    waterFilterElement?.systemType === "combi-box"
      ? "combi-box"
      : "water-filter";

  if (waterFilter == null) return null;

  return (
    <WaterFilterPropertiesElement
      key={waterFilter.id}
      defaultName={waterFilter.name}
      defaultSerialNumber={waterFilter.serial_number}
      defaultPressureDropLimit={waterFilter.pressure_drop_limit}
      defaultInletPressureLimit={waterFilter.inlet_pressure_limit}
      defaultPressureDropNotification={waterFilter.pressure_drop_notification}
      defaultInletPressureNotification={waterFilter.inlet_pressure_notification}
      type={elementType}
      save={save}
      close={() => {
        redirect(getWaterFilterInfoURL(waterFilter.id), true);
      }}
    />
  );
}

export default WaterFilterProperties;
