import { CircuitAssigned } from "../../pages/layouts/plan/properties/controller/ControllerPropertiesContext";
import { ControllerType } from "../../store/interface/HydrawiseSettingsInterface";
import SensorDataInterface from "../../store/interface/SensorDataInterface";
import SensorPropertiesInterface from "../../store/interface/SensorPropertiesInterface";
import WaterFilterDataInterface from "../../store/interface/WaterFilterDataInterface";
import WaterFilterPropertiesInterface from "../../store/interface/WaterFilterPropertiesInterface";
import FertilizerDataInterface from "../../store/interface/FertilizerDataInterface";
import FertilizerPropertiesInterface from "../../store/interface/FertilizerPropertiesInterface";
import { get, postJSON, urlDecorator } from "../../utils/http.utils";
import { HydrawiseSettingsResponseInterface } from "./types";

const CORS_CONFIG = {
  credentials: "same-origin",
  mode: "cors",
};

class CalcApi {
  async generateImage(
    plan_id: string | undefined,
    plan: any,
    layouts: any,
    textsVisibility: any,
    additionalParams: any
  ) {
    console.debug("Generate image");
    if (plan == null) {
      throw new Error("Invalid parameter value");
    }

    const res = await postJSON(
      urlDecorator("$CALC_BACKEND_URL/generatePNG"),
      {
        ident: plan_id,
        plan,
        layouts,
        textsVisibility,
        additionalParams,
      },
      CORS_CONFIG
    );

    const { status, error_msg, response } = await res.json();

    if (status === "ok") return response;

    if (error_msg) throw new Error(error_msg);

    return undefined;
  }

  async recalculateCachesByPlan(plan_id: string) {
    console.debug("recalculate caches");
    if (plan_id == null) {
      throw new Error("Invalid parameter value");
    }

    const res = await postJSON(
      urlDecorator("$CALC_BACKEND_URL/recalculatePlanCaches"),
      {
        ident: plan_id,
      },
      CORS_CONFIG
    );

    const { error_msg } = await res.json();

    if (error_msg) throw new Error(error_msg);

    return;
  }

  async generateSensorCoverage(plan_id: string | undefined, plan: any) {
    console.debug("Generate sensorCoverage");
    if (plan == null) {
      throw new Error("Invalid parameter value");
    }

    const res = await postJSON(
      urlDecorator("$CALC_BACKEND_URL/generateSensorCoverage"),
      {
        ident: plan_id,
        plan,
      },
      CORS_CONFIG
    );

    const { status, error_msg, response } = await res.json();

    if (status === "ok") return response;

    if (error_msg) throw new Error(error_msg);

    return undefined;
  }

  async fetchSensorData(
    serial_number: string[]
  ): Promise<SensorDataInterface[] | undefined> {
    try {
      console.debug("Fetch sensors data");

      const serial_number_str = serial_number
        .map((s) => `serial_number[]=${s}`)
        .join("&");

      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/sensors/sensorHistory?${serial_number_str}`
        ),
        {
          last: true,
        },
        CORS_CONFIG
      );

      const { result } = await response.json();
      if (result) return result;
    } catch (e) {
      console.error("Error in fetch sensor data", e);
    }

    return undefined;
  }

  _insertGapsByThresholdInMs(
    values: SensorDataInterface[] | undefined,
    threshold: number = 24 * 60 * 60 * 1000
  ): SensorDataInterface[] | undefined {
    if (values == null || values.length === 0) {
      return values;
    }

    let result = values.reduce(
      (acc: SensorDataInterface[], value: SensorDataInterface) => {
        const previous = acc.slice(-1)[0];
        if (previous && previous.date && value.date) {
          const previousTime = new Date(previous.date).getTime();
          const valueTime = new Date(value.date).getTime();
          if (Math.abs(valueTime - previousTime) > threshold) {
            acc.push({
              date: Math.min(previousTime, valueTime) + 1,
              serial_number: previous.serial_number,
            });
          }
        }
        acc.push(value);
        return acc;
      },
      []
    );
    return result;
  }

  async fetchSensorHistory(
    serial_number: string
  ): Promise<SensorDataInterface[] | undefined> {
    console.debug("Fetch sensor history");

    try {
      const response = await get(
        urlDecorator(`$SENSOR_BACKEND_URL/sensors/sensorHistory`),
        {
          serial_number,
        },
        CORS_CONFIG
      );

      const { result } = await response.json();
      if (result) {
        //TODO please FIXME when the START/STOP parameters are added to the query
        return this._insertGapsByThresholdInMs(result);
      }
    } catch (e) {
      console.error("Error in fetch sensor data history", e);
    }

    return undefined;
  }

  async fetchSensorProperties(plan_id: string): Promise<any> {
    try {
      console.debug("Fetch sensors properties");

      const response = await get(
        urlDecorator(`$SENSOR_BACKEND_URL/sensors/sensorProperties/${plan_id}`),
        undefined,
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Error in fetch sensor properties", e);
    }

    return undefined;
  }

  async saveSensor(
    id: string,
    plan_id: string,
    properties: SensorPropertiesInterface
  ) {
    try {
      console.debug("Save sensor property");

      const response = await postJSON(
        urlDecorator(`$SENSOR_BACKEND_URL/sensors/sensorProperties`),
        {
          ...properties,
          id,
          plan_id,
        },
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Error in fetch sensor properties", e);
    }

    return undefined;
  }

  async fetchWaterFilterProperties(
    plan_id: string
  ): Promise<WaterFilterPropertiesInterface | undefined> {
    try {
      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/waterfilter/waterfilterProperties/${plan_id}`
        ),
        undefined,
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Error in fetch water filter properties", e);
    }

    return undefined;
  }

  async saveWaterFilter(
    id: string,
    plan_id: string,
    properties: WaterFilterPropertiesInterface
  ) {
    try {
      const response = await postJSON(
        urlDecorator(`$SENSOR_BACKEND_URL/waterfilter/waterfilterProperties`),
        {
          ...properties,
          id,
          plan_id,
        },
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Error in fetch sensor properties", e);
    }

    return undefined;
  }

  async fetchWaterFilterData(
    serial_number: string
  ): Promise<WaterFilterDataInterface[] | undefined> {
    try {
      const serial_number_str = `serial_number[]=${serial_number}`;

      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/waterfilter/waterfilterHistory?${serial_number_str}`
        ),
        {
          last: true,
        },
        CORS_CONFIG
      );

      const { result } = await response.json();
      if (result) return result;
    } catch (e) {
      console.error("Error in fetch sensor data", e);
    }

    return undefined;
  }

  async fetchWaterFilterHistory(
    serial_number: string
  ): Promise<WaterFilterDataInterface[] | undefined> {
    try {
      const serial_number_str = `serial_number[]=${serial_number}`;

      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/waterfilter/waterfilterHistory?${serial_number_str}`
        ),
        CORS_CONFIG
      );

      const { result } = await response.json();
      if (result) return result;
    } catch (e) {
      console.error("Error in fetch sensor data", e);
    }

    return undefined;
  }

  async fetchNotificationProperties(plan_id: string): Promise<any> {
    try {
      console.debug("Fetch notification properties");

      const response = await get(
        urlDecorator(`$SENSOR_BACKEND_URL/notification/properties/${plan_id}`),
        undefined,
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Error in fetch notification properties", e);
    }

    return undefined;
  }

  async saveNotificationProperties(
    plan_id: string,
    email: string | undefined,
    locale: string | undefined
  ) {
    try {
      console.debug("Save notification properties");

      const response = await postJSON(
        urlDecorator(`$SENSOR_BACKEND_URL/notification/properties`),
        {
          plan_id,
          email,
          locale,
        },
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Error in save notification properties", e);
    }

    return undefined;
  }

  async fetchHydrawiseSettings(
    plan_id: string
  ): Promise<HydrawiseSettingsResponseInterface> {
    try {
      console.debug("Fetch hydrawise settings");

      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/smartcontroller/${plan_id}/properties`
        ),
        undefined,
        CORS_CONFIG
      );

      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }

      const result = await response.json();

      if (!result) throw new Error("Hydrawise settings cannot be empty");

      return result;
    } catch (e) {
      console.error("Error in fetch hydrawise settings", e);
      throw e;
    }
  }

  async saveHydrawiseSettings(
    plan_id: string,
    controller_type: ControllerType,
    circuitAssigned: CircuitAssigned,
    locationName: string,
    noIrrTimeStart: string | undefined,
    noIrrTimeStop: string | undefined,
    noIrrDays: number[],
    noIrrTemperatureLimitInC: number | undefined,
    noIrrTemperatureEnabled: boolean,
    noIrrWindSpeedLimitInKmh: number | undefined,
    noIrrWindSpeedEnabled: boolean
  ): Promise<HydrawiseSettingsResponseInterface | undefined> {
    try {
      console.debug("Save hydrawise settings");

      const assigned: {
        circuit_id: string;
        relay_num: number | null;
        sensor_id: string | null;
        default_sensor_ids: string[];
        valve_num: number | undefined;
        et_env_precipitation_in_lm2_per_h: number | undefined;
        living_env_soil_condition_coef: number | undefined;
        living_env_vegetation_coef: number | undefined;
        living_env_root_depth_in_cm: number | undefined;
        living_env_soil_type_coef: number | undefined;
        living_env_sun_exposure_coef: number | undefined;
        living_env_enabled: boolean | undefined;
        et_env_irrigation_type: string | undefined;
        et_env_manual_adj_coef: number | undefined;
        et_env_manual_irrigation_time_in_min: number | undefined;
        et_env_enabled: boolean | undefined;
      }[] = [];

      for (const circuit of circuitAssigned) {
        assigned.push({
          circuit_id: circuit.id,
          relay_num: circuit.relay_num ?? null,
          sensor_id: circuit.sensor_id ?? null,
          default_sensor_ids: circuit.default_sensor_ids ?? [],
          valve_num: circuit?.valve_num,
          et_env_precipitation_in_lm2_per_h:
            circuit?.et_env_precipitation_in_lm2_per_h,
          living_env_soil_condition_coef:
            circuit?.living_env_soil_condition_coef,
          living_env_vegetation_coef: circuit?.living_env_vegetation_coef,
          living_env_root_depth_in_cm: circuit?.living_env_root_depth_in_cm,
          living_env_soil_type_coef: circuit?.living_env_soil_type_coef,
          living_env_sun_exposure_coef: circuit?.living_env_sun_exposure_coef,
          living_env_enabled: circuit?.living_env_enabled,
          et_env_irrigation_type: circuit?.et_env_irrigation_type,
          et_env_manual_adj_coef: circuit?.et_env_manual_adj_coef,
          et_env_manual_irrigation_time_in_min:
            circuit?.et_env_manual_irrigation_time_in_min,
          et_env_enabled: circuit?.et_env_enabled,
        });
      }

      const response = await postJSON(
        urlDecorator(
          `$SENSOR_BACKEND_URL/smartcontroller/${plan_id}/properties`
        ),
        {
          controller_type,
          assigned,
          location_name: locationName,
          no_irr_time_start: noIrrTimeStart,
          no_irr_time_stop: noIrrTimeStop,
          no_irr_days: noIrrDays,
          no_irr_temperature_limit_in_c: noIrrTemperatureLimitInC,
          no_irr_wind_speed_limit_in_kmh: noIrrWindSpeedLimitInKmh,
          no_irr_temperature_enabled: noIrrTemperatureEnabled,
          no_irr_wind_speed_enabled: noIrrWindSpeedEnabled,
        },
        CORS_CONFIG
      );

      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }

      const result = await response.json();
      if (result == null) throw new Error("Hydrawise settings cannot be empty");

      return result;
    } catch (e) {
      console.error("Error in save hydrawise settings", e);
      throw e;
    }
  }

  async loginHunterApi(plan_id: string, hydrawiseApiKey: string) {
    try {
      console.debug("Login to hunterAPI");

      const response = await postJSON(
        urlDecorator(`$SENSOR_BACKEND_URL/smartcontroller/${plan_id}/login`),
        {
          hydrawiseApiKey: hydrawiseApiKey || null,
        },
        CORS_CONFIG
      );

      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
    } catch (e) {
      console.error("Error in login to hunterAPI", e);
      throw e;
    }
  }

  async changeHydrawiseController(plan_id: string, controllerId: string) {
    try {
      console.debug("Change hydrawise controller");

      const response = await postJSON(
        urlDecorator(
          `$SENSOR_BACKEND_URL/smartcontroller/${plan_id}/changeController`
        ),
        {
          controllerId: controllerId || null,
        },
        CORS_CONFIG
      );

      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
    } catch (e) {
      console.error("Error in change hydrawise controller", e);
      throw e;
    }
  }

  async fetchFertilizerProperties(
    plan_id: string
  ): Promise<FertilizerPropertiesInterface | undefined> {
    try {
      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/fertilizer/fertilizerProperties/${plan_id}`
        ),
        undefined,
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Error in fetch fertilizer properties", e);
    }

    return undefined;
  }

  async saveFertilizer(
    id: string,
    plan_id: string,
    properties: FertilizerPropertiesInterface
  ) {
    try {
      const response = await postJSON(
        urlDecorator(`$SENSOR_BACKEND_URL/fertilizer/fertilizerProperties`),
        {
          ...properties,
          id,
          plan_id,
        },
        CORS_CONFIG
      );

      const { result, error } = await response.json();
      if (error) throw new Error(error);

      if (result) return result;
    } catch (e) {
      console.error("Save fertilizer properties failed", e);
    }

    return undefined;
  }

  async fetchFertilizerData(
    serial_number: string
  ): Promise<FertilizerDataInterface[] | undefined> {
    try {
      const serial_number_str = `serial_number[]=${serial_number}`;

      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/fertilizer/fertilizerHistory?${serial_number_str}`
        ),
        {
          last: true,
        },
        CORS_CONFIG
      );

      const { result } = await response.json();
      if (result) return result;
    } catch (e) {
      console.error("Error in fetch fertilizer last data", e);
    }

    return undefined;
  }

  async fetchFertilizerHistory(
    serial_number: string
  ): Promise<FertilizerDataInterface[] | undefined> {
    try {
      const serial_number_str = `serial_number[]=${serial_number}`;

      const response = await get(
        urlDecorator(
          `$SENSOR_BACKEND_URL/fertilizer/fertilizerHistory?${serial_number_str}`
        ),
        CORS_CONFIG
      );

      const { result } = await response.json();
      if (result) return result;
    } catch (e) {
      console.error("Error in fetch fertilizer history data", e);
    }

    return undefined;
  }
}

const calcApi = new CalcApi();
export default calcApi;
