import React from "react";
import { useParams } from "react-router-dom";

import { usePlanStore } from "../../../../store/PlanStore";

import SensorValues from "./SensorValues";
import HistoryLine from "./history/HistoryLine";
import PageInfo from "../PageInfo";

import { getSensorPropertiesURL } from "../../../../utils/path.utils";

import { ReactComponent as ArrowRightSVG } from "../../../../assets/arrow-right.svg";
import { ReactComponent as PencilSVG } from "../../../../assets/pencil.svg";
import RedirectLink from "../../../../components/redirect/RedirectLink";
import Redirect from "../../../../components/redirect/Redirect";

function SensorInfo() {
  const [showMore, changeShowMore] = React.useState(false);
  const { sensorList } = usePlanStore();
  const { sensor_id } = useParams();

  const sensor = React.useMemo(
    () => sensorList?.find((s) => s.id === sensor_id),
    [sensorList, sensor_id]
  );

  if (sensor == null) return null;

  if (sensor.is_fill_properties)
    return <Redirect to={getSensorPropertiesURL(sensor.id)} replace={true} />;

  return (
    <PageInfo>
      <div
        className={["sensor-info", showMore ? "more" : undefined]
          .filter((e) => e)
          .join(" ")}
      >
        <SensorValues sensor={sensor} />

        <HistoryLine sensor={sensor} active={showMore} />

        <RedirectLink
          href={getSensorPropertiesURL(sensor.id)}
          className="edit-button"
        >
          <PencilSVG />
        </RedirectLink>

        <div
          className="show-more-wrap"
          onClick={() => changeShowMore(!showMore)}
        >
          <button type="button" className="show-more">
            <ArrowRightSVG
              style={{
                transform: showMore ? "rotate(-90deg)" : "rotate(90deg)",
              }}
            />
          </button>
        </div>
      </div>
    </PageInfo>
  );
}

export default SensorInfo;
