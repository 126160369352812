import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useUIHelper from "../../../store/uiStore/useUIHelper";
import { usePlanStore } from "../../../store/PlanStore";

import planFactory from "../../../store/factory/planFactory";
import CalculationAnimate from "../popup/CalculationAnimate";
import ErrorPopup from "../popup/ErrorPopup";

import RedirectLink from "../../../components/redirect/RedirectLink";

import { ReactComponent as NotFoundSVG } from "../../../assets/not-found.svg";
import { ReactComponent as MagnifierSVG } from "../../../assets/magnifier.svg";

import { getPlanerURL } from "../../../utils/path.utils";

import calcApi from "../../../service/calcApi";
import resourceApi from "../../../service/resourceApi";

import usePolling from "../../../hooks/usePolling";
import useFetch from "../../../hooks/useFetch";
import hydrawiseSettingsFactory from "../../../store/factory/hydrawiseSettingsFactory";
import { injectCircuitsWithDefaults } from "./properties/controller/sensor-settings/ValvesSettingsTypes";

function PlanDataController() {
  const { formatMessage } = useIntl();

  const {
    planId,
    data,
    sensorList,
    sensorSerialNumbers,
    changeSensorData,
    waterFilterSerialNumber,
    changeWaterFilterData,
    changeData,
    changeSoilNotificationEnabledValue,
    changeSensorProperties,
    changeHydrawiseSettings,
    changeWaterFilterProperties,
    fertilizerSerialNumber,
    changeFertilizerData,
    changeFertilizerProperties,
  } = usePlanStore();

  const { zoomToElements } = useUIHelper();

  const [initialZoomEnabled, changeInitalZoomEnabled] = React.useState(true);

  const fetchPlanData = React.useCallback(async () => {
    if (planId == null) throw new Error("Plan id is not empty");

    const planData = await resourceApi.fetchPlan(planId);
    changeData(planFactory(planData));
  }, [changeData, planId]);

  const fetchNotificationProperties = React.useCallback(async () => {
    if (planId == null) throw new Error("Plan id is not empty");

    const res = await calcApi.fetchNotificationProperties(planId);
    changeSoilNotificationEnabledValue(res?.notification_enabled ?? false);
  }, [changeSoilNotificationEnabledValue, planId]);

  const fetchSensorProperties = React.useCallback(async () => {
    if (planId == null) throw new Error("Plan id is not empty");

    const sensorProperties = await calcApi.fetchSensorProperties(planId);
    changeSensorProperties(sensorProperties);
  }, [changeSensorProperties, planId]);

  const fetchHydrawiseSettings = React.useCallback(async () => {
    if (planId == null) throw new Error("Plan id is not empty");

    const sensorProperties = await calcApi.fetchHydrawiseSettings(planId);
    injectCircuitsWithDefaults(sensorProperties?.assigned);
    const newHydrawiseSettings = hydrawiseSettingsFactory(sensorProperties);

    changeHydrawiseSettings(newHydrawiseSettings);
  }, [changeHydrawiseSettings, planId]);

  const fetchWaterFilterProperties = React.useCallback(async () => {
    if (planId == null) throw new Error("Plan id is not empty");

    const waterFilterProperties = await calcApi.fetchWaterFilterProperties(
      planId
    );

    changeWaterFilterProperties(waterFilterProperties);
  }, [changeWaterFilterProperties, planId]);

  const fetchSensorData = React.useMemo(
    () =>
      sensorSerialNumbers.length > 0
        ? async () => {
            const res = await calcApi.fetchSensorData(sensorSerialNumbers);
            changeSensorData(res);
          }
        : undefined,
    [changeSensorData, sensorSerialNumbers]
  );

  const fetchWaterFilterData = React.useMemo(
    () =>
      waterFilterSerialNumber != null
        ? async () => {
            const res = await calcApi.fetchWaterFilterData(
              waterFilterSerialNumber
            );
            changeWaterFilterData(res);
          }
        : undefined,
    [changeWaterFilterData, waterFilterSerialNumber]
  );

  const fetchFertilizerProperties = React.useCallback(async () => {
    if (planId == null) throw new Error("Plan id is not empty");

    const fertilizerProperties = await calcApi.fetchFertilizerProperties(
      planId
    );

    changeFertilizerProperties(fertilizerProperties);
  }, [changeFertilizerProperties, planId]);

  const fetchFertilizerData = React.useMemo(
    () =>
      fertilizerSerialNumber != null
        ? async () => {
            const res = await calcApi.fetchFertilizerData(
              fertilizerSerialNumber
            );
            changeFertilizerData(res);
          }
        : undefined,
    [changeFertilizerData, fertilizerSerialNumber]
  );

  // fetch plan data
  const initialFetchFn = React.useCallback(async () => {
    await Promise.all([
      fetchPlanData(),
      fetchNotificationProperties(),
      fetchSensorProperties(),
      fetchHydrawiseSettings(),
      fetchWaterFilterProperties(),
      fetchFertilizerProperties(),
    ]);
  }, [
    fetchPlanData,
    fetchNotificationProperties,
    fetchSensorProperties,
    fetchHydrawiseSettings,
    fetchWaterFilterProperties,
    fetchFertilizerProperties,
  ]);

  const fetchingStatus = useFetch(initialFetchFn);

  // fetch and polling sensor data
  usePolling(fetchSensorData, 60 * 1000);
  usePolling(fetchWaterFilterData, 60 * 1000);
  usePolling(fetchFertilizerData, 10 * 60 * 1000);

  // initial zoom
  React.useEffect(() => {
    if (data?.elements != null && initialZoomEnabled) {
      zoomToElements(data?.elements);
      changeInitalZoomEnabled(false);
    }
  }, [data, zoomToElements, initialZoomEnabled]);

  return (
    <>
      {/* Pending animation */}
      {fetchingStatus === "pending" && (
        <CalculationAnimate
          title={formatMessage({ id: "calculation.initial.title" })}
          text={formatMessage({ id: "calculation.text" })}
        />
      )}

      {/* Plan not found */}
      {(planId == null || fetchingStatus === "error") && (
        <ErrorPopup
          Icon={NotFoundSVG}
          title={formatMessage({ id: "common.not_found.title" })}
        >
          <FormattedMessage
            id="common.not_found.text"
            values={{
              link: (
                <RedirectLink href={getPlanerURL()}>
                  {formatMessage({
                    id: "common.not_found.link",
                  })}
                </RedirectLink>
              ),
            }}
          />
        </ErrorPopup>
      )}

      {/* Sensors not found */}
      {data != null && (sensorList == null || sensorList.length === 0) && (
        <ErrorPopup
          Icon={MagnifierSVG}
          title={formatMessage({ id: "common.sensor_not_found.title" })}
        >
          <FormattedMessage
            id="common.sensor_not_found.text"
            values={{
              link: (
                <RedirectLink href={getPlanerURL(planId)}>
                  {formatMessage({
                    id: "common.sensor_not_found.link",
                  })}
                </RedirectLink>
              ),
            }}
          />
        </ErrorPopup>
      )}
    </>
  );
}

export default PlanDataController;
