import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { ReactComponent as ControllerSVG } from "../../../../../assets/controller.svg";

import TextInput from "../../../../../components/inputs/TextInput";
import useRedirect from "../../../../../hooks/useRedirect";
import { usePlanStore } from "../../../../../store/PlanStore";
import { useUserStore } from "../../../../../store/UserStore";
import CalculationAnimate from "../../../popup/CalculationAnimate";
import PropertiesItemWithMultilineInput from "../PropertiesItemWithMultilineInput";
import PropertiesWithDropdown from "../PropertiesWithDropdown";
import PropertiesWrapper from "../PropertiesWrapper";
import TabsNavigation from "../TabsNavigationButtons";
import {
  ControllerPropertiesContext,
  ControllerPropertiesProvider,
} from "./ControllerPropertiesContext";
import PlantEvapotranspirationForm from "./EvapotranspirationForm";
import InterruptForm from "./InterruptForm";
import ValvesSettingsForm from "./ValvesSettingsForm";
import SensorAssignmentForm from "./SensorAssignmentForm";

const defaultTabs = [
  {
    value: "interrupt",
    labelFmt: (formatMessage: ({ id }: { id: string }) => string) =>
      formatMessage({
        id: "controller.properties.interrupt.title",
      }),
    Status: (statusText: string | undefined) => () =>
      <span> {statusText} </span>,
    Component: InterruptForm,
  },
  {
    value: "plant-et",
    labelFmt: (formatMessage: ({ id }: { id: string }) => string) =>
      formatMessage({
        id: "controller.properties.evapotranspiration.title",
      }),
    Status: (statusText: string | undefined) => () =>
      <span> {statusText} </span>,
    Component: PlantEvapotranspirationForm,
  },
];

function ControllerPropertiesElement({ close }: { close: Function }) {
  const { formatMessage } = useIntl();

  const { hydrawiseSettings } = usePlanStore();
  const {
    hydrawiseKey,
    changeHydrawiseKey,
    controllerId,
    changeControllerId,
    activeController,
    circuitAssigned,
    pending,
    loginHunterApi,
    saveController,
    saveHydrawiseSettings,
    toggleSensorAssignmentForm,
    locationName,
    noIrrigationTimeStart,
    noIrrigationTimeStop,
    noIrrigationDays,
    noIrrigationTemperatureLimit,
    noIrrigationTemperatureEnabled,
    noIrrigationWindSpeedLimit,
    noIrrigationWindSpeedEnabled,
  } = React.useContext(ControllerPropertiesContext);

  const user = useUserStore();
  const userIsAdmin = user?.role === "admin";

  const activeMark = formatMessage({
    id: "controller.properties.controller.active_mark.title",
  });
  const getActiveControllerMark = (
    controllerType: string | undefined,
    activeControllerType: string | undefined
  ) => {
    return controllerType === activeControllerType ? activeMark : "";
  };

  const tabs = defaultTabs.map((t) => {
    return {
      value: t.value,
      label: t.labelFmt(formatMessage),
      Status: t.Status(getActiveControllerMark(t.value, activeController)),
      Component: t.Component,
      disabled: t.value === "plant-et" ? !userIsAdmin : undefined,
    };
  });
  const initialTab =
    tabs.find((t) => t.value === activeController)?.value ?? tabs[0]?.value;
  const [selectedTab, changeSelectedTab] = React.useState(initialTab);
  React.useEffect(() => {
    if (activeController) {
      changeSelectedTab(initialTab);
    }
  }, [activeController, initialTab]);

  const disabled =
    hydrawiseSettings?.hydrawise_api_key == null ||
    hydrawiseSettings?.controller_id == null;

  const controllers = React.useMemo(
    () =>
      hydrawiseSettings?.controllers.map((c) => ({
        value: c.id,
        label: c.name ?? c.serial_number,
      })) ?? [],
    [hydrawiseSettings?.controllers]
  );

  return (
    <>
      {pending && (
        <CalculationAnimate title={formatMessage({ id: "common.fetching" })} />
      )}

      <PropertiesWrapper
        title={formatMessage({ id: "controller.properties.title" })}
        ElementIcon={() => <ControllerSVG width={36} height={36} />}
        elementTitle={formatMessage({
          id: "controller.properties.element_title",
        })}
        submit={async () => {
          await loginHunterApi(hydrawiseKey);
          await saveController(controllerId);
          await saveHydrawiseSettings(
            activeController,
            circuitAssigned,
            locationName,
            noIrrigationTimeStart,
            noIrrigationTimeStop,
            noIrrigationDays,
            noIrrigationTemperatureLimit,
            noIrrigationTemperatureEnabled,
            noIrrigationWindSpeedLimit,
            noIrrigationWindSpeedEnabled
          );

          close();
        }}
      >
        <PropertiesItemWithMultilineInput
          className="properties-item "
          informURL={formatMessage({
            id: "controller.properties.hydrawise_key.inform",
          })}
          title={formatMessage({
            id: "controller.properties.hydrawise_key",
          })}
        >
          <TextInput
            value={hydrawiseKey}
            onChange={changeHydrawiseKey}
            onBlur={() => {
              toggleSensorAssignmentForm(false);
              loginHunterApi(hydrawiseKey);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.target.blur();
              }
            }}
          />
        </PropertiesItemWithMultilineInput>

        <PropertiesWithDropdown
          disabled={controllers.length === 0}
          className="properties-item"
          title={formatMessage({ id: "controller.properties.controllers" })}
          informURL={formatMessage({
            id: "controller.properties.controllers.inform",
          })}
          value={controllerId}
          onChange={async (controllerId: string) => {
            toggleSensorAssignmentForm(false);
            changeControllerId(controllerId);
            await saveController(controllerId);
          }}
          data={[{ value: "", label: " " }, ...controllers]}
        />

        <TabsNavigation
          disabled={disabled}
          value={selectedTab}
          onChange={(tab) => {
            toggleSensorAssignmentForm(false);
            changeSelectedTab(tab);
          }}
          data={tabs}
        />
      </PropertiesWrapper>
    </>
  );
}

function ControllerProperties() {
  const { data, hydrawiseSettings } = usePlanStore();
  const { controllerId } = useParams();
  const redirect = useRedirect();

  const controller = React.useMemo(
    () => data?.elements.find((e) => e.id === controllerId),
    [data, controllerId]
  );

  if (controller == null || hydrawiseSettings == null) return null;

  return (
    <ControllerPropertiesProvider key={controller.id}>
      <>
        <ControllerPropertiesElement
          close={() => {
            redirect("/", true);
          }}
        />
        <SensorAssignmentForm />
        <ValvesSettingsForm />
      </>
    </ControllerPropertiesProvider>
  );
}

export default ControllerProperties;
