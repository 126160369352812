import { useUserStore } from "../../../store/UserStore";
import Draggable from "./Draggable";
import Grid from "./Grid";
import Elements from "./Elements";
import Tools from "./tools/Tools";

import ZoomController from "./ZoomController";
import PlanDataController from "./PlanDataController";

import "./plan.scss";

function Plan({ children }: { children: JSX.Element | undefined }) {
  const user = useUserStore();

  return (
    <>
      {user != null && <PlanDataController />}

      <div className="irrigation-plan">
        <ZoomController />
        <div>
          <Draggable>
            <Grid />
            <Elements />
          </Draggable>
        </div>

        {children}

        <Tools />
      </div>
    </>
  );
}

export default Plan;
