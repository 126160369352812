import { DebouncedFunc, debounce } from "lodash";
import React from "react";
import { useIntl } from "react-intl";

import calcApi from "../../../../../service/calcApi";
import { usePlanStore } from "../../../../../store/PlanStore";
import hydrawiseSettingsFactory from "../../../../../store/factory/hydrawiseSettingsFactory";
import {
  ControllerType,
  HydrawiseSettingsInterface,
} from "../../../../../store/interface/HydrawiseSettingsInterface";
import { DialogContext } from "../../../popup/dialog/DialogContext";
import { convertToValidTimeFormat } from "../../../../../utils/format.utils";

export type CircuitAssigned = HydrawiseSettingsInterface["circuits"];

type LoginHunterApiFn = DebouncedFunc<(hydrawiseKey: string) => Promise<void>>;
type SaveControllerFn = DebouncedFunc<(controllerId: string) => Promise<void>>;
type SaveHydrawiseSettingsFn = DebouncedFunc<
  (
    controllerType: ControllerType,
    circuitAssigned: CircuitAssigned,
    locationName: string,
    noIrrTimeStart: string,
    noIrrTimeStop: string,
    noIrrDays: number[],
    noIrrTemperatureLimitInC: number | undefined,
    noIrrTemperatureEnabled: boolean,
    noIrrWindSpeedLimitInKmh: number | undefined,
    noIrrWindSpeedEnabled: boolean
  ) => Promise<void>
>;

type ControllerPropertiesContextInterface = {
  hydrawiseKey: string;
  changeHydrawiseKey: (value: string) => void;
  controllerId: string;
  changeControllerId: (value: string) => void;
  controllerEnabled: boolean | undefined;
  activeController: ControllerType;
  changeActiveController: (value) => void;
  circuitAssigned: CircuitAssigned;
  changeCircuitAssigned: (value: CircuitAssigned) => void;
  hasSensorAssignmentForm: boolean;
  toggleSensorAssignmentForm: (value?: boolean) => void;
  locationName: string;
  changeLocationName: (value: string) => void;
  noIrrigationTimeStart: string;
  changeNoIrrigationTimeStart: (value: string) => void;
  noIrrigationTimeStop: string;
  changeNoIrrigationTimeStop: (value: string) => void;
  noIrrigationDays: number[];
  changeNoIrrigationDays: (value: number[]) => void;
  noIrrigationTemperatureLimit: number | undefined;
  changeNoIrrigationTemperatureLimit: (value: number) => void;
  noIrrigationWindSpeedLimit: number | undefined;
  changeNoIrrigationWindSpeedLimit: (value: number | undefined) => void;
  noIrrigationTemperatureEnabled: boolean;
  changeNoIrrigationTemperatureEnabled: (value: boolean) => void;
  noIrrigationWindSpeedEnabled: boolean;
  changeNoIrrigationWindSpeedEnabled: (value: boolean) => void;
  hasValvesSettingsForm: {
    value: boolean;
    activeTab?: "living_environment" | "irrigation_settings";
  };
  toggleValvesSettings: (
    value: boolean,
    activeTab?: "living_environment" | "irrigation_settings"
  ) => void;
  pending: boolean;
  loginHunterApi: LoginHunterApiFn;
  saveController: SaveControllerFn;
  saveHydrawiseSettings: SaveHydrawiseSettingsFn;
};

const ControllerPropertiesContext =
  React.createContext<ControllerPropertiesContextInterface>({
    hydrawiseKey: "",
    changeHydrawiseKey(value) {},
    controllerId: "",
    changeControllerId(value) {},
    controllerEnabled: undefined,
    activeController: undefined,
    changeActiveController: (value) => {},
    circuitAssigned: [],
    changeCircuitAssigned(value) {},
    hasSensorAssignmentForm: false,
    toggleSensorAssignmentForm: (value) => {},
    locationName: "",
    changeLocationName: (value) => {},
    noIrrigationTimeStart: "",
    changeNoIrrigationTimeStart: (value) => {},
    noIrrigationTimeStop: "",
    changeNoIrrigationTimeStop: (value) => {},
    noIrrigationDays: [],
    changeNoIrrigationDays: (value) => {},
    noIrrigationTemperatureLimit: undefined,
    changeNoIrrigationTemperatureLimit: (value) => {},
    noIrrigationWindSpeedLimit: undefined,
    changeNoIrrigationWindSpeedLimit: (value) => {},
    noIrrigationTemperatureEnabled: false,
    changeNoIrrigationTemperatureEnabled: (value) => {},
    noIrrigationWindSpeedEnabled: false,
    changeNoIrrigationWindSpeedEnabled: (value) => {},
    hasValvesSettingsForm: {
      value: false,
    },
    toggleValvesSettings: (value, activeTab) => {},
    pending: false,
    loginHunterApi: (async (hydrawiseKey) => {}) as LoginHunterApiFn,
    saveController: (async (controllerId) => {}) as SaveControllerFn,
    saveHydrawiseSettings: (async (
      controllerType,
      circuitAssigned,
      locationName,
      noIrrTimeStart,
      noIrrTimeStop,
      noIrrDays,
      noIrrTemperatureLimitInC,
      noIrrTemperatureEnabled,
      noIrrWindSpeedLimitInKmh,
      noIrrWindSpeedEnabled
    ) => {}) as SaveHydrawiseSettingsFn,
  });

function ControllerPropertiesProvider({ children }: { children: JSX.Element }) {
  const { formatMessage } = useIntl();
  const { showAlert } = React.useContext(DialogContext);
  const { planId, hydrawiseSettings, changeHydrawiseSettings } = usePlanStore();

  const [hydrawiseKey, changeHydrawiseKey] = React.useState("");
  const [controllerId, changeControllerId] = React.useState("");
  const [circuitAssigned, changeCircuitAssigned] =
    React.useState<CircuitAssigned>([]);
  const [activeController, changeActiveController] =
    React.useState<ControllerType>();
  const [controllerEnabled, changeControllerEnabled] = React.useState<
    boolean | undefined
  >(undefined);

  const [hasSensorAssignmentForm, changeSensorAssignmentForm] =
    React.useState(false);

  // PLANT-ET controller
  const [locationName, changeLocationName] = React.useState("");

  const [noIrrigationTimeStart, changeNoIrrigationTimeStart] =
    React.useState("");
  const [noIrrigationTimeStop, changeNoIrrigationTimeStop] = React.useState("");
  const [noIrrigationDays, changeNoIrrigationDays] = React.useState<number[]>(
    []
  );
  const [noIrrigationTemperatureLimit, changeNoIrrigationTemperatureLimit] =
    React.useState<number | undefined>();
  const [noIrrigationTemperatureEnabled, changeNoIrrigationTemperatureEnabled] =
    React.useState(false);

  const [noIrrigationWindSpeedLimit, changeNoIrrigationWindSpeedLimit] =
    React.useState<number | undefined>();
  const [noIrrigationWindSpeedEnabled, changeNoIrrigationWindSpeedEnabled] =
    React.useState(false);

  const [hasValvesSettingsForm, changeValvesSettingsForm] = React.useState<{
    value: boolean;
    activeTab?: "living_environment" | "irrigation_settings";
  }>({
    value: false,
    activeTab: undefined,
  });

  // refresh after settings changed
  React.useEffect(() => {
    const defaultHydrawiseKey = hydrawiseSettings?.hydrawise_api_key ?? "";
    const defaultControllerId = hydrawiseSettings?.controller_id ?? "";
    const defaultCircuitAssigned = hydrawiseSettings?.circuits ?? [];
    const defaultActiveController =
      hydrawiseSettings?.controller_type ?? undefined;
    const defaultControllerEnabled = defaultActiveController != null;

    // ET-controller
    const defaultLocationName = hydrawiseSettings?.location_name ?? "";

    const defaultNoIrrigationTimeStart =
      hydrawiseSettings?.no_irr_time_start ?? "";
    const defaultNoIrrigationTimeStop =
      hydrawiseSettings?.no_irr_time_stop ?? "";
    const defaultNoIrrigationDays = hydrawiseSettings?.no_irr_days ?? [];

    const defaultNoIrrigationTemperatureLimit =
      hydrawiseSettings?.no_irr_temperature_limit_in_c;
    const defaultNoIrrigationTemperatureEnabled =
      hydrawiseSettings?.no_irr_temperature_enabled ?? false;
    const defaultNoIrrigationWindSpeedLimit =
      hydrawiseSettings?.no_irr_wind_speed_limit_in_kmh;
    const defaultNoIrrigationWindSpeedEnabled =
      hydrawiseSettings?.no_irr_wind_speed_enabled ?? false;

    changeHydrawiseKey(defaultHydrawiseKey);
    changeControllerId(defaultControllerId);
    changeCircuitAssigned(defaultCircuitAssigned);
    changeActiveController(defaultActiveController);
    changeControllerEnabled(defaultControllerEnabled);

    // ET-controller props
    changeLocationName(defaultLocationName);

    changeNoIrrigationTimeStart(defaultNoIrrigationTimeStart);
    changeNoIrrigationTimeStop(defaultNoIrrigationTimeStop);
    changeNoIrrigationDays(defaultNoIrrigationDays);

    changeNoIrrigationTemperatureLimit(defaultNoIrrigationTemperatureLimit);
    changeNoIrrigationTemperatureEnabled(defaultNoIrrigationTemperatureEnabled);
    changeNoIrrigationWindSpeedLimit(defaultNoIrrigationWindSpeedLimit);
    changeNoIrrigationWindSpeedEnabled(defaultNoIrrigationWindSpeedEnabled);
  }, [hydrawiseSettings]);

  const toggleSensorAssignmentForm = React.useCallback((value?: boolean) => {
    changeSensorAssignmentForm((v) => {
      if (value != null) return value;
      return !v;
    });
  }, []);

  const toggleValvesSettings = React.useCallback(
    (
      value: boolean,
      activeTab?: "living_environment" | "irrigation_settings"
    ) => {
      changeValvesSettingsForm((v) => {
        if (!value) return { value };

        return { value, activeTab };
      });
    },
    []
  );

  const [pending, changePending] = React.useState(false);

  const previousActiveController =
    React.useRef<ControllerType>(activeController);
  React.useEffect(() => {
    const showEtWarningPopup = async () => {
      await showAlert(
        formatMessage({
          id: "controller.properties.evapotranspiration.activation.popup.text",
        }),
        formatMessage({
          id: "controller.properties.evapotranspiration.activation.popup.title",
        })
      );
    };
    if (activeController === "plant-et" && controllerEnabled) {
      return;
    }

    if (
      previousActiveController.current !== "plant-et" &&
      activeController === "plant-et"
    ) {
      showEtWarningPopup();
      toggleValvesSettings(true, "irrigation_settings");
    }
    previousActiveController.current = activeController;
  }, [
    activeController,
    formatMessage,
    controllerEnabled,
    pending,
    showAlert,
    toggleValvesSettings,
  ]);

  const loginHunterApi = React.useMemo(
    () =>
      debounce(async (hydrawiseKey: string) => {
        hydrawiseKey = hydrawiseKey?.trim();

        if (planId == null) throw new Error("PlanId cannot be null");

        if (hydrawiseKey === hydrawiseSettings?.hydrawise_api_key) {
          return;
        }

        try {
          changePending(true);
          await calcApi.loginHunterApi(planId, hydrawiseKey);
          const newHydrawiseSettings = await calcApi.fetchHydrawiseSettings(
            planId
          );
          if (newHydrawiseSettings == null) {
            throw new Error("HydrawiseSettings cannot be null");
          }

          const settings = hydrawiseSettingsFactory(newHydrawiseSettings);
          changeHydrawiseKey(settings.hydrawise_api_key ?? "");
          changeHydrawiseSettings(settings);
          changeControllerEnabled(settings.controller_type != null);
        } catch (e: any) {
          console.error("loginHunterApi error", e);

          changePending(false);
          await showAlert(
            e.message.indexOf("500") >= 0
              ? formatMessage({ id: "controller.properties.error.login" })
              : formatMessage({
                  id: "controller.properties.error.invalid_key",
                }),
            formatMessage({ id: "controller.properties.error.title" })
          );
        } finally {
          changePending(false);
        }
      }, 300),
    [
      changeHydrawiseSettings,
      formatMessage,
      hydrawiseSettings?.hydrawise_api_key,
      planId,
      showAlert,
    ]
  );

  const saveController = React.useMemo(
    () =>
      debounce(async (controllerId: string) => {
        controllerId = controllerId?.trim();
        changeControllerId(controllerId);

        if (planId == null) throw new Error("PlanId cannot be null");

        if (controllerId === hydrawiseSettings?.controller_id) {
          return;
        }

        try {
          changePending(true);
          await calcApi.changeHydrawiseController(planId, controllerId);
          const newHydrawiseSettings = await calcApi.fetchHydrawiseSettings(
            planId
          );
          if (newHydrawiseSettings == null) {
            throw new Error("HydrawiseSettings cannot be null");
          }

          const settings = hydrawiseSettingsFactory(newHydrawiseSettings);
          changeControllerId(settings.controller_id ?? "");
          changeHydrawiseSettings(settings);
        } catch (e) {
          console.error("saveController error", e);
          await showAlert(
            formatMessage({ id: "controller.properties.error.controller" }),
            formatMessage({ id: "controller.properties.error.title" })
          );
        } finally {
          changePending(false);
        }
      }, 300),
    [
      changeHydrawiseSettings,
      formatMessage,
      hydrawiseSettings?.controller_id,
      planId,
      showAlert,
    ]
  );

  const saveHydrawiseSettings = React.useMemo(
    () =>
      debounce(
        async (
          controllerType: ControllerType,
          circuitAssigned: CircuitAssigned,
          locationName: string,
          noIrrTimeStart: string | undefined,
          noIrrTimeStop: string | undefined,
          noIrrDays: number[],
          noIrrTemperatureLimitInC: number | undefined,
          noIrrTemperatureEnabled: boolean,
          noIrrWindSpeedLimitInKmh: number | undefined,
          noIrrWindSpeedEnabled: boolean
        ) => {
          changeControllerEnabled(undefined);
          if (planId == null) throw new Error("PlanId cannot be null");

          if (
            hydrawiseSettings?.hydrawise_api_key == null ||
            hydrawiseSettings?.controller_id == null
          ) {
            return;
          }

          noIrrTimeStart = convertToValidTimeFormat(noIrrTimeStart);
          noIrrTimeStop = convertToValidTimeFormat(noIrrTimeStop);

          try {
            changePending(true);

            const hydrawiseSettings = await calcApi.saveHydrawiseSettings(
              planId,
              controllerType,
              circuitAssigned,
              locationName,
              noIrrTimeStart,
              noIrrTimeStop,
              noIrrDays,
              noIrrTemperatureLimitInC,
              noIrrTemperatureEnabled,
              noIrrWindSpeedLimitInKmh,
              noIrrWindSpeedEnabled
            );
            if (hydrawiseSettings == null) {
              throw new Error("HydrawiseSettings cannot be null");
            }

            const settings = hydrawiseSettingsFactory(hydrawiseSettings);
            changeHydrawiseSettings(settings);
            changeCircuitAssigned(settings.circuits);
            changeControllerEnabled(controllerType != null);
          } catch (e) {
            console.error("saveHydrawiseSettings error", e);
            await showAlert(
              formatMessage({ id: "controller.properties.error.settings" }),
              formatMessage({ id: "controller.properties.error.title" })
            );
          } finally {
            changePending(false);
          }
        },
        300
      ),
    [
      changeHydrawiseSettings,
      formatMessage,
      hydrawiseSettings?.controller_id,
      hydrawiseSettings?.hydrawise_api_key,
      planId,
      showAlert,
    ]
  );

  const value = React.useMemo(() => {
    return {
      hydrawiseKey,
      changeHydrawiseKey,
      controllerId,
      changeControllerId,
      controllerEnabled,
      activeController,
      changeActiveController,
      circuitAssigned,
      changeCircuitAssigned,
      hasSensorAssignmentForm,
      locationName,
      changeLocationName,
      noIrrigationTimeStart,
      changeNoIrrigationTimeStart,
      noIrrigationTimeStop,
      changeNoIrrigationTimeStop,
      noIrrigationDays,
      changeNoIrrigationDays,
      noIrrigationTemperatureLimit,
      changeNoIrrigationTemperatureLimit,
      noIrrigationTemperatureEnabled,
      changeNoIrrigationTemperatureEnabled,
      noIrrigationWindSpeedLimit,
      changeNoIrrigationWindSpeedLimit,
      noIrrigationWindSpeedEnabled,
      changeNoIrrigationWindSpeedEnabled,
      toggleSensorAssignmentForm,
      hasValvesSettingsForm,
      toggleValvesSettings,
      pending,
      loginHunterApi,
      saveController,
      saveHydrawiseSettings,
    };
  }, [
    hydrawiseKey,
    controllerId,
    controllerEnabled,
    activeController,
    changeActiveController,
    circuitAssigned,
    hasSensorAssignmentForm,
    locationName,
    noIrrigationTimeStart,
    noIrrigationTimeStop,
    noIrrigationDays,
    noIrrigationTemperatureLimit,
    noIrrigationTemperatureEnabled,
    noIrrigationWindSpeedLimit,
    noIrrigationWindSpeedEnabled,
    toggleSensorAssignmentForm,
    hasValvesSettingsForm,
    toggleValvesSettings,
    pending,
    loginHunterApi,
    saveController,
    saveHydrawiseSettings,
  ]);

  return (
    <ControllerPropertiesContext.Provider value={value}>
      {children}
    </ControllerPropertiesContext.Provider>
  );
}

export { ControllerPropertiesContext, ControllerPropertiesProvider };
