import React from "react";
import { useIntl } from "react-intl";
import useFetch from "../hooks/useFetch";
import CalculationAnimate from "../pages/layouts/popup/CalculationAnimate";
import LoginPopup from "../pages/layouts/popup/LoginPopup";
import resourceApi from "../service/resourceApi";

interface UserInterface {
  username: string;
  role: string;
  email: string;
  [key: string]: any;
}

const UserContext = React.createContext<UserInterface | undefined>(undefined);

function useUserStore() {
  return React.useContext(UserContext);
}

function UserProvider({ children }: { children: JSX.Element }) {
  const { formatMessage } = useIntl();

  const [user, changeUser] = React.useState(undefined);
  const [fetched, changeFetched] = React.useState(false);

  const fetchUserData = React.useCallback(async () => {
    try {
      changeUser(await resourceApi.fetchUserInfo());
    } finally {
      changeFetched(true);
    }
  }, []);

  useFetch(fetchUserData);

  return (
    <UserContext.Provider value={user}>
      {!fetched && (
        <CalculationAnimate
          title={formatMessage({ id: "calculation.initial.title" })}
          text={formatMessage({ id: "calculation.text" })}
        />
      )}
      {fetched && user == null && <LoginPopup />}

      {children}
    </UserContext.Provider>
  );
}

export { useUserStore, UserProvider };
