import { URL_CONSTANTS } from "../config/config";

const checkResponse = (res: Response) => {
  if (!res.ok) {
    console.error("Response failed: " + res.status);
    throw new Error(`${res.status}: ${res.statusText}`);
  }
};

/**
 * Wrapper for sending one line POST requests with FormData.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postFormData = async (
  url: string,
  body?: any,
  defaultConfig: any = {}
): Promise<Response> => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {}),
    },
  };

  return post(url, convertModelToFormData(body), config);
};

/**
 * Convert JSON to FormData
 * @param {*} model
 * @param {*} form
 * @param {*} namespace
 */
const convertModelToFormData = (
  model: any = {},
  form?: FormData,
  namespace: string = ""
): FormData => {
  let formData = form || new FormData();
  for (let propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || model[propertyName] == null)
      continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName]);
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any, index: any) => {
        if (typeof element != "object")
          formData.append(`${formKey}[]`, element);
        else {
          const tempFormKey = `${formKey}[${index}]`;
          convertModelToFormData(element, formData, tempFormKey);
        }
      });
    } else if (
      typeof model[propertyName] === "object" &&
      !(model[propertyName] instanceof File)
    ) {
      convertModelToFormData(model[propertyName], formData, formKey);
    } else {
      formData.append(formKey, model[propertyName].toString());
    }
  }
  return formData;
};

/**
 * Wrapper for sending one line GET requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const get = async (url: string, params?: any, config: any = {}) => {
  const separator = url.search(/\?/) >= 0 ? "&" : "?";

  const response = await fetch(
    url +
      (params && Object.keys(params).length > 0
        ? separator + new window.URLSearchParams(params)
        : ""),
    {
      ...config,
      credentials: config.credentials || "same-origin",
    }
  );

  checkResponse(response);

  return response;
};

/**
 * Wrapper for sending one line POST requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const post = async (url: string, body?: any, defaultConfig: any = {}) => {
  const config = {
    ...defaultConfig,
    credentials: defaultConfig.credentials || "same-origin",
    method: defaultConfig.method || "POST",
    headers: {
      ...(defaultConfig.headers || {}),
    },
    body: body != null ? body : undefined,
  };

  const response = await fetch(url + "", config);

  checkResponse(response);

  return response;
};

/**
 * Wrapper for sending one line POST requests with JSON.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postJSON = (url: string, body?: any, defaultConfig: any = {}) => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {}),
      "Content-Type": "application/json",
    },
  };
  return post(url, JSON.stringify(body), config);
};

function urlDecorator(url: string) {
  return Object.entries(URL_CONSTANTS).reduce(
    (r, [key, value]) => r.replace(key, value ?? ""),
    url
  );
}

export { get, post, postFormData, postJSON, urlDecorator };
