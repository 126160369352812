import { useIntl } from "react-intl";

import Logo from "./Logo";

import { ReactComponent as SmartGardenLogoSVG } from "../../../assets/smart-garden-logo.svg";
import { ReactComponent as ToPlanLogoSVG } from "../../../assets/to-plan-logo.svg";
import { ReactComponent as AvatarSVG } from "../../../assets/avatar.svg";

import { useUserStore } from "../../../store/UserStore";
import { usePlanStore } from "../../../store/PlanStore";
import RedirectLink from "../../../components/redirect/RedirectLink";
import { urlDecorator } from "../../../utils/http.utils";
import { getPlanerURL } from "../../../utils/path.utils";

import "./header.scss";

function Header() {
  const { formatMessage } = useIntl();

  const user = useUserStore();
  const { planId, data } = usePlanStore();

  return (
    <>
      <header>
        <div className="container">
          <Logo key={data?.planName} planName={data?.planName ?? ""} />

          <div className="smart-garden header-col">
            <SmartGardenLogoSVG />
            <span>{formatMessage({ id: "header.garden" })}</span>
          </div>

          <div className="right-col">
            {data && (
              <RedirectLink
                className="to-plan header-col"
                target="planer"
                href={getPlanerURL(planId)}
              >
                <ToPlanLogoSVG />
                <div className="title">
                  {formatMessage({ id: "header.plan" })}
                </div>
              </RedirectLink>
            )}

            <RedirectLink
              className="user header-col"
              href={urlDecorator(`$SHOP_URL/jtl.php`)}
            >
              <AvatarSVG />
              <div className="title">{user?.username}</div>
            </RedirectLink>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
