import React, { CSSProperties } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { usePlanStore } from "../../../../store/PlanStore";
import PropertiesWrapper from "./PropertiesWrapper";

import TextInput from "../../../../components/inputs/TextInput";
import IntegerInput from "../../../../components/inputs/number-input/IntegerInput";
import Checkbox from "../../../../components/checkbox/Checkbox";
import PropertiesWithDropdown from "./PropertiesWithDropdown";

import PropertiesItemWithInput from "./PropertiesItemWithInput";
import PropertiesItemWithControl from "./PropertiesItemWithControl";

import { ReactComponent as SensorSVG } from "../../../../assets/sensor-properties-icon.svg";
import { ReactComponent as Moisture1SVG } from "../../../../assets/moisture-1.svg";

import { debounce } from "lodash";
import { getSensorInfoURL } from "../../../../utils/path.utils";
import useRedirect from "../../../../hooks/useRedirect";
import { PLANT_TYPES, SOIL_TYPES } from "../../../../config/config";
import SensorPropertiesInterface, {
  SensorSoilType,
} from "../../../../store/interface/SensorPropertiesInterface";
import calcApi from "../../../../service/calcApi";

function SensorPropertiesElement({
  save,
  close,
  defaultName,
  defaultSerialNumber,
  defaultSoilType,
  defaultPlantType,
  defaultMaxSoilMoisture,
  defaultMinSoilMoisture,
  defaultWiltingPoint,
  defaultUpperSensor,
  defaultLowerSensor,
  defaultLowerSensorEnabled,
}: {
  save: Function;
  close: Function;
  defaultName?: string;
  defaultSerialNumber?: string;
  defaultSoilType?: string;
  defaultPlantType?: string;
  defaultMaxSoilMoisture?: number;
  defaultMinSoilMoisture?: number;
  defaultWiltingPoint?: number;
  defaultUpperSensor?: number;
  defaultLowerSensor?: number;
  defaultLowerSensorEnabled?: boolean;
}) {
  const { formatMessage } = useIntl();

  const [name, changeName] = React.useState(defaultName ?? "");

  const [serial_number, changeSerialNumber] = React.useState(
    defaultSerialNumber ?? ""
  );

  const [soil_type, changeSoilType] = React.useState(defaultSoilType);

  const [plant_type, changePlanType] = React.useState(defaultPlantType);

  const [max_soil_moisture, changeMaxSoilMoisture] = React.useState(
    defaultMaxSoilMoisture
  );

  const [min_soil_moisture, changeMinSoilMoisture] = React.useState(
    defaultMinSoilMoisture
  );

  const [wilting_point, changeWiltingPoint] =
    React.useState(defaultWiltingPoint);

  const [upper_sensor, changeUppperSensor] = React.useState(defaultUpperSensor);

  const [lower_sensor, changeLowerSensor] = React.useState(defaultLowerSensor);

  const [lower_sensor_enabled, changeLowerSensorEnabled] = React.useState(
    defaultLowerSensorEnabled
  );

  const initialRef = React.useRef(false);

  // save after update
  React.useEffect(() => {
    if (initialRef.current) {
      save({
        name,
        serial_number,
        soil_type,
        plant_type,
        max_soil_moisture,
        min_soil_moisture,
        wilting_point,
        upper_sensor,
        lower_sensor,
        lower_sensor_enabled,
      });
    }
    initialRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lower_sensor,
    lower_sensor_enabled,
    max_soil_moisture,
    min_soil_moisture,
    name,
    plant_type,
    serial_number,
    soil_type,
    upper_sensor,
    wilting_point,
  ]);

  return (
    <PropertiesWrapper
      title={formatMessage({ id: "sensor.properties.title" })}
      ElementIcon={SensorSVG}
      elementTitle={name}
      submit={() => {
        save({
          name,
          serial_number,
          soil_type,
          plant_type,
          max_soil_moisture,
          min_soil_moisture,
          wilting_point,
          upper_sensor,
          lower_sensor,
          lower_sensor_enabled,
        });
        close();
      }}
    >
      <PropertiesItemWithInput
        className="properties-item"
        title={formatMessage({ id: "sensor.properties.name" })}
      >
        <TextInput value={name} onChange={changeName} />
      </PropertiesItemWithInput>

      <PropertiesItemWithInput
        className="properties-item"
        title={formatMessage({ id: "sensor.properties.serial_number" })}
      >
        <TextInput
          value={serial_number}
          className="input-uppercase"
          onChange={(v: string) => changeSerialNumber(v.toUpperCase())}
        />
      </PropertiesItemWithInput>

      <PropertiesWithDropdown
        className="properties-item"
        title={formatMessage({ id: "sensor.properties.soil_type" })}
        value={soil_type}
        onChange={(v: SensorSoilType) => {
          if (v != null) {
            const { max_soil_moisture, min_soil_moisture, wilting_point } =
              SOIL_TYPES[v];

            if (max_soil_moisture) changeMaxSoilMoisture(max_soil_moisture);
            if (min_soil_moisture) changeMinSoilMoisture(min_soil_moisture);
            if (wilting_point) changeWiltingPoint(wilting_point);
          }
          changeSoilType(v);
        }}
        data={[
          {
            value: undefined,
            label: formatMessage({ id: "sensor.soil_type.unknown" }),
          },
          ...Object.entries(SOIL_TYPES).map(([typeId, type]) => ({
            value: typeId,
            label: formatMessage(type.title),
          })),
        ]}
      />

      <PropertiesWithDropdown
        className="properties-item"
        title={formatMessage({ id: "sensor.properties.plant_type" })}
        value={plant_type}
        onChange={changePlanType}
        data={[
          {
            value: undefined,
            label: formatMessage({ id: "sensor.plant_type.unknown" }),
          },
          ...Object.entries(PLANT_TYPES).map(([typeId, type]) => ({
            value: typeId,
            label: formatMessage(type.title),
          })),
        ]}
      />

      <div className="properties-separator" />

      <PropertiesItemWithControl
        key={["max_soil_moisture", soil_type].filter((e) => e).join("-")}
        className="properties-item"
        Icon={Moisture1SVG}
        title={formatMessage({ id: "sensor.properties.max_soil_moisture" })}
        value={max_soil_moisture}
        valueDelta={0.5}
        max={100}
        valueUnit="%"
        onChange={changeMaxSoilMoisture}
        style={
          {
            "--color": "#00c4ff",
          } as CSSProperties
        }
      />

      <div className="properties-separator" />

      <PropertiesItemWithControl
        key={["min_soil_moisture", soil_type].filter((e) => e).join("-")}
        className="properties-item"
        Icon={Moisture1SVG}
        title={formatMessage({ id: "sensor.properties.min_soil_moisture" })}
        value={min_soil_moisture}
        valueDelta={0.5}
        max={100}
        valueUnit="%"
        onChange={changeMinSoilMoisture}
        style={
          {
            "--color": "#ff7600",
          } as CSSProperties
        }
      />

      <div className="properties-separator" />

      <PropertiesItemWithControl
        key={["wilting_point", soil_type].filter((e) => e).join("-")}
        className="properties-item"
        Icon={Moisture1SVG}
        title={formatMessage({ id: "sensor.properties.wilting_point" })}
        value={wilting_point}
        valueDelta={0.5}
        max={100}
        valueUnit="%"
        onChange={changeWiltingPoint}
        style={
          {
            "--color": "#ed1e79",
          } as CSSProperties
        }
      />

      <div className="properties-separator" />

      <div className="properties-item">
        <div>
          <div className="block-title">
            {formatMessage({ id: "sensor.properties.installation_depth" })}
          </div>
          <PropertiesItemWithInput
            className="properties-item"
            title={formatMessage({ id: "sensor.properties.upper_sensor" })}
            unit={formatMessage({ id: "common.units.cm" })}
          >
            <IntegerInput
              value={upper_sensor}
              onChange={changeUppperSensor}
              max={50}
            />
          </PropertiesItemWithInput>
          <PropertiesItemWithInput
            className="properties-item"
            Icon={() => (
              <Checkbox
                value={lower_sensor_enabled}
                onChange={changeLowerSensorEnabled}
              />
            )}
            title={formatMessage({ id: "sensor.properties.lower_sensor" })}
            unit={formatMessage({ id: "common.units.cm" })}
          >
            <IntegerInput
              value={lower_sensor}
              onChange={changeLowerSensor}
              max={50}
              disabled={!lower_sensor_enabled}
            />
          </PropertiesItemWithInput>
        </div>
      </div>
    </PropertiesWrapper>
  );
}

function SensorProperties() {
  const { planId, sensorList, changeSensorProperties } = usePlanStore();
  const { sensor_id } = useParams();
  const redirect = useRedirect();

  const sensor = React.useMemo(
    () => sensorList?.find((s) => s.id === sensor_id),
    [sensorList, sensor_id]
  );

  const saveSensor = React.useCallback(
    async (id: string, properties: SensorPropertiesInterface) => {
      if (planId == null || sensorList == null) {
        throw new Error(`Sensor list is empty`);
      }

      const sensor = sensorList?.find((s) => s.id === id);
      if (sensor == null) throw new Error(`Sensor ${id} not found`);

      const sensorData = {
        ...properties,
        id: sensor.id,
      } as SensorPropertiesInterface;

      changeSensorProperties((list) => [
        ...(list?.filter((s) => s.id !== id) ?? []),
        sensorData,
      ]);

      const updatedSensor = await calcApi.saveSensor(
        sensor.id,
        planId,
        sensorData
      );

      if (updatedSensor != null) {
        changeSensorProperties((list) => [
          ...(list?.filter((s) => s.id !== id) ?? []),
          updatedSensor,
        ]);
      }
    },
    [changeSensorProperties, planId, sensorList]
  );

  const save = React.useMemo(
    () =>
      debounce((data) => {
        if (sensor == null) return;
        saveSensor(sensor.id, data);
      }, 300),
    [saveSensor, sensor]
  );

  if (sensor == null) return null;

  return (
    <SensorPropertiesElement
      key={sensor.id}
      defaultName={sensor?.name}
      defaultSerialNumber={sensor?.serial_number}
      defaultSoilType={sensor?.soil_type}
      defaultPlantType={sensor?.plant_type}
      defaultMaxSoilMoisture={sensor?.max_soil_moisture}
      defaultMinSoilMoisture={sensor?.min_soil_moisture}
      defaultWiltingPoint={sensor?.wilting_point}
      defaultUpperSensor={sensor?.upper_sensor}
      defaultLowerSensor={sensor?.lower_sensor}
      defaultLowerSensorEnabled={sensor?.lower_sensor_enabled}
      save={save}
      close={() => {
        redirect(getSensorInfoURL(sensor.id), true);
      }}
    />
  );
}

export default SensorProperties;
